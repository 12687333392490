import Image from "next/image";

type MainPortalImageProps = {
	src: string;
	alt: string;
	size?: string;
	className?: string;
};

export default function MainPortalImage(props: MainPortalImageProps) {
	return (
		<div className={props.className}>
			<Image
				priority
				src={props.src}
				alt={props.alt}
				sizes="100vw"
				style={{
					width: props.size,
					height: "auto",
				}}
				width={1112}
				height={900}
			/>
		</div>
	);
}
