import Image from "next/image";

type ReviewCardProps = {
	text?: string;
	profilePhoto: string;
	name?: string;
	affiliation?: string;
};

export default function ReviewCard(props: ReviewCardProps) {
	return (
		<div className="bg-white text-center p-4 mx-4">
			<div className="mx-auto flex flex-col w-full items-center gap-4">
				<Image
					src={props.profilePhoto}
					alt={props.name ?? "profile"}
					height={80}
					width={80}
					className="rounded-full"
				/>
				<div className="text-center">
					<h2 className="text-xl font-semibold text-gray-ipbt">{props.name}</h2>
					<p className="text-lg text-gray-ipbt">{props.affiliation}</p>
				</div>
			</div>
			<div className="py-2">-</div>
			<p className="text-lg text-gray-500 text-center leading-relaxed">
				{props.text}
			</p>
		</div>
	);
}
