import classNames from "classnames";
import type { PropsWithChildren } from "react";

type SectionHeadingProps = PropsWithChildren<{
	className?: string;
}>;

export function HeadSection(props: SectionHeadingProps) {
	return (
		<h2
			className={classNames(
				"text-center text-2xl font-bold xl:text-left xl:text-5xl text-slate-strong-ipbt",
				props.className,
			)}
		>
			{props.children}
		</h2>
	);
}
