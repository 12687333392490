import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Slide from '@mui/material/Slide';
import Snackbar from '@mui/material/Snackbar';
import Link from 'next/link';
import type { SyntheticEvent } from 'react';
import { useEffect, useState } from 'react';
import Slider from 'react-slick';

import ProductCard from '@/components/card/ProductCard';
import ModalDetailImage from '@/components/modal/ModalDetailImage';
import { useAuth } from '@/context/auth';
import { SERVICE_PRODUCT } from '@/services';
import { SERVICE_WISHLIST } from '@/services/wishlist.service';
import { CONSTANTS } from '@/utils';
import { warningNotif } from '@/utils/notifications';

import type { Product } from '@/types/product';
import { HeadSection } from '../typography/HeadSection';

type ISectionNearestProductProps = {
  variant: 'technical' | 'ldp' | 'ila';
};

const linkRedirectMap: Record<string, string> = {
  ldp: 'ldp-program',
  ila: 'ila-program',
  technical: 'technical',
};

const settings = {
  dots: false,
  variableWidth: true,
  autoplay: true,
  speed: 1000,
  autoplaySpeed: 3200,
  cssEase: 'linear',
  arrows: false,
  responsive: [
    {
      breakpoint: 780,
      settings: {
        arrows: true,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        arrows: true,
      },
    },
  ],
};

const SectionNearestProduct = (props: ISectionNearestProductProps) => {
  const { auth } = useAuth();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [products, setProducts] = useState<Product[]>([]);
  const [openDetailImage, setOpenDetailImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [message, setMessage] = useState('');

  const handleFetchTopTraining = () => {
    let idUser = '';
    if (auth.id) {
      idUser = String(auth.id);
    }
    SERVICE_PRODUCT.topProduct(
      `?id_portofolio=${CONSTANTS.PORTOFOLIO[props.variant]}&id_user=${idUser}`,
      (res: any) => {
        // TODO(elianiva): properly handle this
        if (!res.success) return;
        setProducts(res.payload.data);
      },
    );
  };

  function handleAddWishlist(idProduct: number, addToWishlist: boolean) {
    if (!auth?.id) {
      warningNotif(
        'Silahkan login terlebih dahulu untuk menambahkan produk kedalam wishlist',
      );
      return;
    }
    if (addToWishlist) {
      SERVICE_WISHLIST.createWishlist(auth.id, idProduct, (res: Response) => {
        if (res.success) {
          setOpenSnackbar(true);
          setMessage('Berhasil menambahkan produk dalam wishlist');
        }
      });
      return;
    }
    SERVICE_WISHLIST.deleteWishlist(auth.id, idProduct, (res: Response) => {
      if (res.success) {
        setOpenSnackbar(true);
        setMessage('Berhasil menghapus produk dalam wishlist');
      }
    });
  }

  function handleCloseWishlist(
    _event: SyntheticEvent | Event,
    reason?: string,
  ) {
    if (reason === 'clickaway') return;
    setOpenSnackbar(false);
  }

  useEffect(() => {
    handleFetchTopTraining();
  }, [auth.id]);

  return (
    <div className="w-full bg-blue-productcard py-20 px-8">
      <div className="bg-blue-productcard max-w-screen-4xl mx-auto">
        <HeadSection>Program terdekat</HeadSection>
        <p className="text-center text-lg text-gray-500 xl:text-left xl:text-2xl">
          Temukan berbagai program terdekat di sini
        </p>
        <Slider
          {...settings}
          className="mt-4 w-full bg-blue-productcard xl:mt-8"
        >
          {products.map((item, index) => (
            <ProductCard
              key={`item-product-${item.id}-${index}`}
              id={item.id}
              image={item.cover}
              imageAlt={item.name}
              price={item.price}
              corePrice={item.core_price ?? 0}
              rScore={4.5}
              chair={item.participant_capacity - (item.count_participant ?? 0)}
              title={item.name}
              type={item.studymethod?.method_name}
              setOpenDetailImage={setOpenDetailImage}
              setSelectedImage={setSelectedImage}
              addedToWishlist={
                Object.prototype.hasOwnProperty.call(item, 'wishlist') &&
                (item.wishlist?.length ?? 0) > 0
              }
              onClickWishlist={(addToWishlist) =>
                handleAddWishlist(item.id, addToWishlist)
              }
            />
          ))}
        </Slider>
      </div>
      <Link href={`${linkRedirectMap[props.variant]}`}>
        <button
          type="button"
          className="mx-auto bg-white text-blue-ipbt border border-blue-ipbt rounded-lg px-6 py-3 font-medium block mt-16"
        >
          Lihat semua program
        </button>
      </Link>
      <ModalDetailImage
        open={openDetailImage}
        image={selectedImage}
        onClose={() => setOpenDetailImage(false)}
      />
      <Snackbar
        ContentProps={{
          className: 'bg-slate-600',
        }}
        TransitionComponent={Slide}
        open={openSnackbar}
        autoHideDuration={1500}
        onClose={handleCloseWishlist}
        message={message}
      />
    </div>
  );
};

export { SectionNearestProduct };
