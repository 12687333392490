import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Image from "next/image";
import { useEffect, useState } from "react";
import Slider from "react-slick";

import { CONSTANTS } from "@/utils";

import { SERVICE_CLIENT } from "@/services";

const settings = {
	dots: false,
	infinite: true,
	speed: 2000,
	autoplaySpeed: 2000,
	cssEase: "linear",
	slidesToShow: 6,
	slidesToScroll: 1,
	autoplay: true,
	arrows: false,
	responsive: [
		{
			breakpoint: 780,
			settings: {
				slidesToShow: 3,
				slidesToScroll: 1,
			},
		},
		{
			breakpoint: 1024,
			settings: {
				slidesToShow: 4,
				slidesToScroll: 1,
			},
		},
	],
};

type ClientSectionProps = {
	variant: "technical" | "ldp" | "ila";
};

export function ClientsSection(props: ClientSectionProps) {
	// TODO: proper type definitions
	const [dataClient, setDataClient] = useState<Record<string, string>[]>([]);

	useEffect(() => {
		SERVICE_CLIENT.topClient(
			`?id_portofolio=${CONSTANTS.PORTOFOLIO[props.variant]}`,
			(res: Response) => {
				setDataClient(res.payload?.data ?? []);
			},
		);
	}, [props.variant]);

	return (
		<div className="w-full bg-gray-light-ipbt py-20 mix-blend-multiply!">
			<Slider {...settings} className="my-auto max-w-screen-3xl mx-auto">
				{dataClient.map((client) => (
					<div
						key={client.logo_url}
						className=" bg-gray-light-ipbt mix-blend-multiply! outline-hidden grayscale hover:filter-none"
					>
						<Image
							src={client.logo_url ?? ""}
							width="400"
							height="80"
							alt="Icon Client"
							className="bg-light-gray-ipbt mx-0 my-auto mix-blend-multiply! outline-hidden"
						/>
					</div>
				))}
			</Slider>
		</div>
	);
}
