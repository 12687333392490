import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import React, { useEffect, useState } from "react";
import Slider from "react-slick";

import ReviewCard from "@/layout/ReviewCard";
import { SERVICE_TESTIMONI } from "@/services";
import { CONSTANTS } from "@/utils";

import type { Testimonial } from "@/types/testimonial";
import { HeadSection } from "../typography/HeadSection";

const settings = {
	infinite: true,
	slidesToShow: 3,
	slidesToScroll: 3,
	autoplay: true,
	speed: 500,
	autoplaySpeed: 3000,
	responsive: [
		{
			breakpoint: 780,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
			},
		},
		{
			breakpoint: 1024,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 2,
			},
		},
	],
	// cssEase: "linear",
};

type ISectionReviewProps = {
	variant: "technical" | "ldp" | "ila";
};

const SectionReview = (props: ISectionReviewProps) => {
	const [testimoni, setTestimoni] = useState<Testimonial[]>([]);
	const handleFetchTestimoni = () => {
		SERVICE_TESTIMONI.listTestimoni(
			`?id_portofolio=${CONSTANTS.PORTOFOLIO[props.variant]}`,
			(res: Response) => {
				setTestimoni(res.payload?.data ?? []);
			},
		);
	};

	useEffect(() => {
		handleFetchTestimoni();
	}, []);

	return (
		<div className="mx-auto w-full bg-white max-w-screen-4xl px-4 py-10">
			<HeadSection>Yang klien kami rasakan</HeadSection>
			<p className="text-gray-ipbt text-lg xl:text-2xl">
				Berikut adalah ulasan dari para pelanggan kami
			</p>
			<Slider {...settings} className="m-auto mt-8 w-full bg-white">
				{testimoni.map((item) => (
					<ReviewCard
						key={`list-testimoni-${item.id}`}
						profilePhoto={item.photo}
						affiliation={item.occupation}
						name={item.name}
						text={item.description}
					/>
				))}
			</Slider>
		</div>
	);
};

export { SectionReview };
