import classNames from 'classnames';
import Link from 'next/link';

type MainButtonsProps = {
  button: string;
  bgButton?: string;
  className?: string;
  redirect: string;
};

export default function MainButtons(props: MainButtonsProps) {
  return (
    <div className={classNames('flex justify-center gap-2 lg:gap-4', props.className)}>
      <Link href={props.redirect}>
        <button
          type="button"
          className={classNames(
            'rounded-lg whitespace-nowrap px-4 py-3 font-semibold text-white xs:px-[2.4rem] xs:py-4 md:px-10 md:py-5 text-lg',
            props.bgButton,
          )}
        >
          {props.button}
        </button>
      </Link>
      <Link href="/schedule">
        <button
          type="button"
          className="rounded-lg whitespace-nowrap px-4 py-3 font-semibold text-blue-ipbt border border-blue-ipbt xs:px-[2.4rem] xs:py-4 md:px-10 md:py-5 text-lg"
        >
          Jadwal program
        </button>
      </Link>
    </div>
  );
}
